import * as React from "react";
import Logo from "../images/ABx Logo Blue.png";

const TermsPage = ({ location, data }) => {
  return (
    <main className="bg-abx-light-grey">
      <title>Alchemy Box - Terms of Service</title>
      <div className="px-16 py-8">
        <div>
          <img src={Logo} className="w-24 mb-8" />
        </div>

        <div className="mb-4">
          <span className="text-abx-dark-blue text-2xl">Terms of Service</span>
        </div>
        <p className="text-abx-dark-grey pt-2 pb-2">
          The Alchemy Box CFO in the cloud is a virtual assistant that helps
          businesses stay in Buenos and not run out of money. As with all
          artificial intelligence it relies on data to be optimal, accurate and
          effective - so we take privacy seriously. This policy sets outer
          commitment to our community and visitors; including what data we
          collect, on what basis and how it is protected.
        </p>
        <p className="text-abx-dark-grey pt-2 pb-2">
          These Terms of Service govern your use of the website located at{" "}
          <a href="https://www.alchemybox.io">https://www.alchemybox.io</a> and
          any related services provided by Alchemy Box.{" "}
        </p>
        <p className="text-abx-dark-grey pt-2 pb-2">
          By accessing{" "}
          <a href="https://www.alchemybox.io">https://www.alchemybox.io</a>, you
          agree to abide by these Terms of Service and to comply with all
          applicable laws and regulations. If you do not agree with these Terms
          of Service, you are prohibited from using or accessing this website or
          using any other services provided by Alchemy Box.{" "}
        </p>
        <p className="text-abx-dark-grey pt-2 pb-2">
          We, Alchemy Box, reserve the right to review and amend any of these
          Terms of Service at our sole discretion. Upon doing so, we will update
          this page. Any changes to these Terms of Service will take effect
          immediately from the date of publication.{" "}
        </p>

        <br />
        <span className="text-abx-dark-blue text-xl mb-8 mt-8">
          Limitations of Use
        </span>
        <p className="text-abx-dark-grey pt-2 pb-2">
          By using this website, you warrant on behalf of yourself, your users,
          and other parties you represent that you will not:{" "}
        </p>
        <ol className="text-abx-dark-grey list-decimal pl-12 pt-2">
          <li>
            modify, copy, prepare derivative works of, decompile, or reverse
            engineer any materials and software contained on this website;
          </li>
          <li>
            remove any copyright or other proprietary notations from any
            materials and software on this website;
          </li>
          <li>
            transfer the materials to another person or &ldquo;mirror&rdquo; the
            materials on any other server;
          </li>
          <li>
            knowingly or negligently use this website or any of its associated
            services in a way that abuses or disrupts our networks or any other
            service Alchemy Box provides;
          </li>
          <li>
            use this website or its associated services to transmit or publish
            any harassing, indecent, obscene, fraudulent, or unlawful material;
          </li>
          <li>
            use this website or its associated services in violation of any
            applicable laws or regulations;
          </li>
          <li>
            use this website in conjunction with sending unauthorised
            advertising or spam;
          </li>
          <li>
            harvest, collect or gather user data without the user’s consent; or
          </li>
          <li>
            use this website or its associated services in such a way that may
            infringe the privacy, intellectual property rights, or other rights
            of third parties.
          </li>
        </ol>
        <br />
        <span className="text-abx-dark-blue text-xl mb-8">
          Intellectual Property
        </span>
        <p className="text-abx-dark-grey pt-2 pb-2">
          The intellectual property in the materials contained in this website
          are owned by or licensed to Alchemy Box and are protected by
          applicable copyright and trademark law. We grant our users permission
          to download one copy of the materials for personal, non-commercial
          transitory use.{" "}
        </p>
        <p className="text-abx-dark-grey pt-2 pb-2">
          This constitutes the grant of a license, not a transfer of title. This
          license shall automatically terminate if you violate any of these
          restrictions or the Terms of Service, and may be terminated by Alchemy
          Box at any time.{" "}
        </p>
        <br />
        <span className="text-abx-dark-blue text-xl mb-8">Liability</span>
        <p className="text-abx-dark-grey pt-2 pb-2">
          Our website and the materials on our website are provided on an 'as
          is' basis. To the extent permitted by law, Alchemy Box makes no
          warranties, expressed or implied, and hereby disclaims and negates all
          other warranties including, without limitation, implied warranties or
          conditions of merchantability, fitness for a particular purpose, or
          non-infringement of intellectual property, or other violation of
          rights.{" "}
        </p>
        <p className="text-abx-dark-grey pt-2 pb-2">
          n no event shall Alchemy Box or its suppliers be liable for any
          consequential loss suffered or incurred by you or any third party
          arising from the use or inability to use this website or the materials
          on this website, even if Alchemy Box or an authorised representative
          has been notified, orally or in writing, of the possibility of such
          damage.{" "}
        </p>
        <p className="text-abx-dark-grey pt-2 pb-2">
          n the context of this agreement, &ldquo;consequential loss&rdquo;
          includes any consequential loss, indirect loss, real or anticipated
          loss of profit, loss of benefit, loss of revenue, loss of business,
          loss of goodwill, loss of opportunity, loss of savings, loss of
          reputation, loss of use and/or loss or corruption of data, whether
          under statute, contract, equity, tort (including negligence),
          indemnity or otherwise.{" "}
        </p>
        <p className="text-abx-dark-grey pt-2 pb-2">
          Because some jurisdictions do not allow limitations on implied
          warranties, or limitations of liability for consequential or
          incidental damages, these limitations may not apply to you.{" "}
        </p>
        <br />
        <span className="text-abx-dark-blue text-xl mb-8">
          Accuracy of Materials
        </span>
        <p className="text-abx-dark-grey pt-2 pb-2">
          The materials appearing on our website are not comprehensive and are
          for general information purposes only. Alchemy Box does not warrant or
          make any representations concerning the accuracy, likely results, or
          reliability of the use of the materials on this website, or otherwise
          relating to such materials or on any resources linked to this website.{" "}
        </p>
        <br />
        <span className="text-abx-dark-blue text-xl mb-8">Links</span>
        <p className="text-abx-dark-grey pt-2 pb-2">
          Alchemy Box has not reviewed all of the sites linked to its website
          and is not responsible for the contents of any such linked site. The
          inclusion of any link does not imply endorsement, approval or control
          by Alchemy Box of the site. Use of any such linked site is at your own
          risk and we strongly advise you make your own investigations with
          respect to the suitability of those sites.{" "}
        </p>
        <br />
        <span className="text-abx-dark-blue text-xl mb-8">
          Right to Terminate
        </span>
        <p className="text-abx-dark-grey pt-2 pb-2">
          We may suspend or terminate your right to use our website and
          terminate these Terms of Service immediately upon written notice to
          you for any breach of these Terms of Service.{" "}
        </p>
        <br />
        <span className="text-abx-dark-blue text-xl mb-8">Severance</span>
        <p className="text-abx-dark-grey pt-2 pb-2">
          Any term of these Terms of Service which is wholly or partially void
          or unenforceable is severed to the extent that it is void or
          unenforceable. The validity of the remainder of these Terms of Service
          is not affected.{" "}
        </p>
        <br />
        <span className="text-abx-dark-blue text-xl mb-8">Governing Law</span>
        <p className="text-abx-dark-grey pt-2 pb-2">
          These Terms of Service are governed by and construed in accordance
          with the laws of United Kingdom. You irrevocably submit to the
          exclusive jurisdiction of the courts in that State or location.{" "}
        </p>
        <p className="text-abx-dark-grey pt-2 pb-2 text-sm font-light">
          Last updated: 22 September 2021{" "}
        </p>
      </div>
    </main>
  );
};

export default TermsPage;
